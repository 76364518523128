.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.form-control-feedback {
  /* color: red;
  font-size: 12px;
  font-weight: 500; */

  /* display: none; */
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #dc3545;
}

.text-color-link {
  color: #4154f1;
  text-decoration: none;
}

.cursor-pointer {
  cursor: pointer;
}

.mouse-pointer {
  cursor: pointer;
}

.text-end {
  text-align: end;
}

.g-font-size-10 {
  font-size: 10px;
}

.g-font-size-11 {
  font-size: 11px;
}

.g-font-size-12 {
  font-size: 12px;
}

.g-font-size-13 {
  font-size: 13px;
}

.g-font-size-14 {
  font-size: 14px;
}

.g-font-size-15 {
  font-size: 15px;
}

.g-font-size-16 {
  font-size: 16px !important;
}

.nav-link {
  color: #2c384e;
  /* display: block;
  padding: var(--bs-nav-link-padding-y) var(--bs-nav-link-padding-x);
  font-size: var(--bs-nav-link-font-size);
  font-weight: var(--bs-nav-link-font-weight);
  color: var(--bs-nav-link-color);
  text-decoration: none;
  transition: color .15s ease-in-out,background-col */
}

.g-pt-10 {
  padding-top: 10px;
}

.g-pt-20 {
  padding-top: 20px;
}

.g-pt-50 {
  padding-top: 50px;
}

.text-left {
  /* align-content: flex-start; */
  text-align: left !important;
}

.w-20 {
  width: 20% !important;
}

.w-50 {
  width: 50% !important;
}

.w-20 {
  width: 20% !important;
}

@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+Thai:wght@400&family=Open+Sans:wght@300&display=swap");

* {
  font-family: "Noto Sans Thai", sans-serif !important;
  /* font-family: 'Open Sans', sans-serif !important; */
}

h1,
h2,
h3,
h4,
h5,
.card-title {
  font-family: "Noto Sans Thai", sans-serif !important;
  /* font-family: 'Open Sans', sans-serif !important; */
}

.text-gray {
  color: gray;
}

.text-dark-blue {
  color: #4154f1;
}

.text-light-blue {
  color: #899bbd;
}

.g-text-cursor {
  cursor: pointer;
}

.text-right {
  text-align: right;
}

.dropzone {
  border: 2px dashed #cccccc;
  border-radius: 4px;
  background: #f7f7f7;
  padding: 20px;
  text-align: center;
}

.trHover:hover {
  text-decoration: underline;
  color: #4154f1;
  font-weight: 600;
}
