input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type=number] {
    -moz-appearance: textfield;
}

.bg-menu {
    background-color: #cfe2ff;
    border-radius: 10px  ;
    /* --bs-alert-color: #084298; */
    /* --bs-alert-bg: #cfe2ff; */
    /* --bs-alert-border-color: #b6d4fe; */
}